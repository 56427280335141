
export function getDeadlineColor(dueDate, status) {
    const now = new Date()
    const dateDiff = Math.floor((dueDate - now) / 86400000)
    if ( ["Done", "Skipped", "Missed"].includes(status)) {
        return;
    } else if (dateDiff <=0){
        //Overdue
        return {backgroundColor: "#B06161", color:"white", fontWeight: 'bold' }

    } else if (dateDiff <=14){
        // due in next two weeks
        return {backgroundColor: "#F0DBAF", fontWeight: 'bold'};
    
    } else if (dateDiff <=30){
        // due in next month
        return {backgroundColor: "#A5DD9B", color:"darkgreen", fontWeight: 'bold'}
    
    } else if (dateDiff <=92){
        // due in next two months
        return {backgroundColor: "#F3F8FF", color:"blue", fontWeight: 'bold'};

    }
        


}
