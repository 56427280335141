import * as tconst from "../../common/Constants";
import GetAssigneeListFromItems from "../../okr/GetAssigneeListFromItems";

export function PrepareData(items, selectedArea, selectedPeriod, selectedStatus,selectedAssignee, selectedDueDateFilter) {


    const filteredItemsByArea = items.filter((item) => { return item.area === selectedArea; });
    const objectives = filteredItemsByArea.filter((item) => { return item.type === "Objective"; });
    
    const keyResults = filteredItemsByArea.filter((item) => { 
        return item.type === "Key Result" && 
            CheckPeriod(selectedPeriod, item.period) &&
            CheckAssignee(selectedAssignee, item.Assignee) &&
            CheckStatus(selectedStatus, item.status) &&
            CheckDueDate(selectedDueDateFilter, item.dueDate)

        });
    


    try {
        //Empty childrens lists before adding them again.
        objectives.map((obj) => { 
            obj.children = [];
            return null;
        });
        keyResults.map((kr) => {

            let a = objectives.find((obj) => obj.id === kr.parent);
            if (a) {
                a.children.push(kr);
            }
            else {
                let b = objectives.find((obj) => obj.id === 0);
                if (b) {
                    b.children.push(kr);
                }
                else {
                    b = { id: 0, title: "Unparented", children: [] };
                    b.children.push(kr);
                    objectives.push(b);
                }
            }
            return null;
        });

        const filteredItems = objectives.filter((obj) => {
            return (obj.children.length > 0 || 
                    (CheckPeriod(selectedPeriod, obj.period)  && 
                        CheckAssignee(selectedAssignee, obj.Assignee) &&
                        CheckStatus(selectedStatus, obj.status) && 
                        CheckDueDate(selectedDueDateFilter, obj.dueDate)

            ));
        });

        const _assignees =  GetAssigneeListFromItems(filteredItems);
        return {filteredItems, _assignees};
        
    } catch (error) {
        console.error("Prepare Data failed: "+ error);
        throw error;        
    }
}

function CheckPeriod(selectedPeriod, periodList) {
    try {
        if (selectedPeriod === tconst.PERIOD_ALL){
            return true;
        } else if (selectedPeriod === tconst.PERIOD_MISSING){
            return periodList.length = 0;
        }
        else{
            return periodList.includes(selectedPeriod)
        }
            
    } catch (error) {
        console.warn("CheckPeriod failed: "+ error)
        return true;
    }
}

function CheckAssignee(selectedAssignee, assignee) {
    try {
        if (selectedAssignee === tconst.ASSIGNEE_ALL) {
            return true;
        }
        else if (selectedAssignee === tconst.ADO_UNASSIGNED) {
            return assignee === tconst.ADO_UNASSIGNED 
        }else {
            if (selectedAssignee) {
                return selectedAssignee === assignee;
            }
            else {
                return true
            }
        }
            
    } catch (error) {
        console.warn("CheckAssignee failed: "+ error)
        return true;        
    }
}
function CheckStatus(selectedStatus, status) {
    try {
        if (selectedStatus === tconst.STATUS_ALL) {
            return true;
        }
        else if (selectedStatus === tconst.STATUS_ACTIVE){
            return (tconst.STATUS_ACTIVE_LIST.includes(status))
        }
        else if (selectedStatus === tconst.STATUS_COMPLETED){
            return (tconst.STATUS_COMPLETE_LIST.includes(status))
        }
        else if (selectedStatus === tconst.STATUS_CANDIDATE){
            return (tconst.STATUS_CANDIDATES_LIST.includes(status))
        }
        else {
            return (status === selectedStatus)
        }
            
    } catch (error) {
        console.warn("CheckStatus failed: "+ error)
        return true;        
    }
}

function CheckDueDate(selectedDueDateFilter, dueDate) {
    try {
        
        if (selectedDueDateFilter === tconst.DUE_ALL) {
            return true;
        }
        else if ((selectedDueDateFilter === tconst.DUE_MISSING)) {
            return isNaN(dueDate);
        }
        else {
            const _limit = new Date(Date.now());            
            _limit.setHours(23,59,0);
            _limit.setDate(_limit.getDate() + selectedDueDateFilter);

            return (dueDate <= _limit);
        }
    }
    catch (error){
        console.warn("CheckDueDate failed: "+ error)
        return true;
    }
}
