import * as tconst from "../common/Constants";

 function GetAssigneeListFromItems(_items) {
    return [..._items.map((item) => { return item.Assignee; }), "<All>"]
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort()
        //TODO: Make sure we include the team in the list
        .map((item) => { return { label: item, value: item, team: tconst.ASSIGNEE_TEAM_ALL }; });
}

export default GetAssigneeListFromItems;
