import React, { Component } from "react";
import { getProjects } from "../../api/ticketsApiAzure";

class LoginAdo extends Component {
  state = {
    un: "",
    pw: "",
    error: "",
  };
  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };
  onSubmit = (event) => {
    event.preventDefault();
    const auth = { un: this.state.un, pw: this.state.pw };
    getProjects(auth)
      .then((permissions) => {
        console.log("myPermissions", permissions);
        this.setState({ error: "" });
        this.props.updateAuth(auth);
      })
      .catch((err) => {
        console.error("Checking myPermissions failes", err);
        this.setState({ error: "Authentication failed" });
      });
  };

  render() {
    return (
      <>
        <div className="mx-auto" style={{ width: 400 + "px" }}>
          <form onSubmit={this.onSubmit}>
            <h3>Azure DevOps Login</h3>
            <label>Username</label>
            <input
              type="text"
              name="un"
              className="form-control"
              placeholder={"Type username"}
              value={this.state.un}
              onChange={this.handleChange}
            />
            <br />
            <label>Personal Access Token</label>
            <input
              type="password"
              name="pw"
              className="form-control"
              placeholder={"Type password"}
              value={this.state.pw}
              onChange={this.handleChange}
            />
            <div></div>
            <br />
            {this.state.error && (
              <>
                <div className="alert alert-danger">{this.state.error} <br/> See details about <a href="https://learn.microsoft.com/en-us/azure/devops/organizations/accounts/use-personal-access-tokens-to-authenticate">Personal Access Token</a></div> <br />
                  
              </>
            )}

            {<input type="submit" value="Login" className="btn btn-primary" />}
          </form>
        </div>
      </>
    );
  }
}

export default LoginAdo;
