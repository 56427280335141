import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Route } from "react-router-dom";
import "./App.css";
import { RequestProvider } from "./api/Azure/requestContainer";
import * as LocalConst from "./common/localVariableDoNotCheckIn";
import store from 'store2';
import OkrPage from "./okr/OkrPage";

class App extends Component {
  constructor(props) {
    super(props);
    // ToDo Store Data in Local storage in browser 
    const _authAdo_un = store.get("Auth_un");
    const _authAdo_pw = store.get("Auth_pw");
    const _authAdo = {un: _authAdo_un ? _authAdo_un : "", pw: _authAdo_pw ? _authAdo_pw : ""};

    this.state = 
    { 
        authAdo:  { un: _authAdo ? _authAdo.un : LocalConst.authAdoUsername, pw: _authAdo ? _authAdo.pw :  LocalConst.authAdoPassword }
    };
  }

  updateAuthAdo = (auth) => {
    const _authAdo = {un: auth.un, pw: auth.pw};
    store.set("Auth_un", auth.un );
    store.set("Auth_pw", auth.pw);
    this.setState({ authAdo: _authAdo});

  }

  render() {
    return (
      <RequestProvider>  
        <Router>
          <Route
            path="/"
            exact
            render={(props) => <OkrPage auth={this.state.authAdo} updateAuth={this.updateAuthAdo} {...props} />}
            />
          <Route path="/ado" render={(props) => <OkrPage auth={this.state.authAdo} updateAuth={this.updateAuthAdo} {...props} />} />
        </Router>
      </RequestProvider>
    );
  }
}

export default App;
