import * as tConst from "../common/Constants";

export default function EnrichTicket (item){
    const _tags =  (item.fields[tConst.AF_TAGS] ? item.fields[tConst.AF_TAGS] : "").split(";");
    item.tags = _tags
    item.period = _tags.filter((tag) => {
        const period =  tag.split("Q")
        const validperiod = (period.length =2 && (1*period[0]>=23) && (1*period[1]>=1) && ((1*period[1])<=4));
        return validperiod
    }).map((item) => {return item.trim()})
    const pp = item.fields[tConst.AF_PLANNING_PRIORITY]
    item.planningPri = ((pp) ? 1*pp : 99999 )
    item.Assignee = item.fields[tConst.AF_ASSIGNED_TO] ? item.fields[tConst.AF_ASSIGNED_TO].displayName : tConst.ADO_UNASSIGNED;
    const _area = item.fields[tConst.AF_AREA_PATH] ? item.fields[tConst.AF_AREA_PATH] : "";
    item.area = "\\"+_area;
    const _type = item.fields[tConst.AF_WORKITEM_TYPE] ? item.fields[tConst.AF_WORKITEM_TYPE] : "";
    item.type = _type;
    const _parent = item.fields[tConst.AF_PARENT] ?  item.fields[tConst.AF_PARENT] : "";
    item.parent = _parent;
    const _progress = 1 * (item.fields[tConst.AF_PROGRESS] ?  item.fields[tConst.AF_PROGRESS] : 0);
    item.progress = _progress;

    item.dependencyRawText = item.fields[tConst.AF_DEPENDECIES] ? item.fields[tConst.AF_DEPENDECIES] : ""; 
    
    const _dependencies = item.dependencyRawText.split(",").map((item) => {
        const _itemSplitted = item.split("|");
        if (_itemSplitted.length >=2) {
            return {
                team: _itemSplitted[0].trim(),
                itemId:  _itemSplitted[1].trim() 
            }     
        } else {
            return {
                team: _itemSplitted[0].trim(),
            }     
        }
    });
    console.log("2024-05-01: " +JSON.stringify(_dependencies, null, 3));
    item.dependencies =  _dependencies
    item.dueDate = new Date(item.fields[tConst.AF_DUE_DATE]);
    item.dueDateText = item.fields[tConst.AF_DUE_DATE] ? tConst.formatDate(new Date(item.fields[tConst.AF_DUE_DATE])) : "";
    item.title = item.fields[tConst.AF_TITLE];
    item.status = item.fields[tConst.AF_STATE];
    item.children = [];


    return ;

}