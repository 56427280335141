import * as tConst from "../common/Constants";

export default function getStatusColor(status) {
    //return {backgroundColor: ""}//, color:"white", fontWeight: 'bold' }
    if (status === "On Track") {
        return {backgroundColor: "#A5DD9B", color:"darkgreen", fontWeight: 'bold'}
    }
    else if (status === "Done") {
        return {backgroundColor: tConst.DONE_BACKGROUND_COLOR, color:"white", fontWeight: 'bold'};
    }
    else if (status === "At Risk" ){
        return {backgroundColor: "#F0DBAF", fontWeight: 'bold'};
    } 
    else if (status === "Partial"){
        return {backgroundColor: "khaki", fontWeight: 'bold'};
    } 
    else if (status === "Off Track" ){
        return {backgroundColor: "darksalmon", color:"white", fontWeight: 'bold' }
    } 
    else if (status ==="Missed"){
        return {backgroundColor: "#B06161", color:"white", fontWeight: 'bold' }
    } 
    else if (status === "Dropped"){
        return {backgroundColor: "#F3EEEA", color:"darkgrey", fontWeight: 'bold'};
    } 
    else if (status === "Committed"){
        return {backgroundColor: "lightblue", fontWeight: 'bold'};
    } 
    else if (status === "Proposed"){
        return {backgroundColor: "#DEECFF", color:"darkblue",fontWeight: 'bold'};
    } 
    else if (status === "Idea"){
        return {backgroundColor: "#F3F8FF", color:"blue", fontWeight: 'bold'};
    } 
    else {
        return {backgroundColor: tConst.INVALID_BACKGROUND_COLOR};
    }
} 