import React, { useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import * as tconst from "../common/Constants";
//import * as tConst from "../common/Constants";



const EditOKR = ({...props}) => {
    // eslint-disable-next-line no-unused-vars
    const [title, setTitle] = useState(null);
    const [ticketStatus, setTicketStatus] = useState(null);
    const [assignee, setAssignee] = useState(null);
    const [progress, setProgress] = useState(null);
    const [dependencies, setDependencies] = useState(null);
    const [periods, setPeriods ] = useState(null);
    const [dueDate, setDueDate] = useState(null);

    const width_proportion1 = '100%';
    const width_proportion2 = '95%';
    const inputStyle = { width:width_proportion1 }
        
    const tempDueDate = !isNaN(Date.parse(props.selectedItem?.dueDate)) ? props.selectedItem?.dueDate.toISOString().substring(0,10) : null ;
    
    if (props.selectedItem && props.showEditOKR){
     
    return(
        <>
            <Modal size="lg" show={props.showEditOKR} >
                <Modal.Header>
                    <Modal.Title>
                        Edit item {props.selectedItem.id}                &nbsp;
                        <Button onClick={()=>{
                            window.open(tconst.AZURE_DEVOPS_BASE_TICKET_URI+props.selectedItem.id, '_blank');
                            close()}}>
                    Open ADO
                </Button>
                    </Modal.Title>

                </Modal.Header>
            <Modal.Body>
                <table>
                    <tbody>

                        <tr>
                            <td width="10%">
                                <label>Title</label> 
                            </td>
                            <td width="1%"></td>
                            <td width="35%">                    
                                <input 
                                    style={inputStyle} 
                                    defaultValue={props.selectedItem?.title} 
                                    onChange={(e) =>{
                                        if (e.target.value === props.selectedItem?.title) {
                                            setTitle(null);
                                        }
                                        else {
                                            setTitle(e.target.value);
                                        }
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Path</label>
                            </td>
                            <td></td>
                            <td>                    
                                <input style={inputStyle} defaultValue={props.selectedArea} disabled></input>
                            </td>
                        </tr>
                        <tr>                        
                            <td>
                                <label>Assignee</label>
                            </td>
                            <td></td>
                            <td>
                                {props.selectedItem.Assignee}
                                {/*}                    
                            <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    options={props.assigneeList}
                                    style={{ width: "40px"  }}
                                    name="assignee"
                                    value={props.assigneeList.filter((filt) => {
                                        console.log("2024-04-11 assignee: "+ assignee +" props.selectedItem.Assignee: "+ props.selectedItem.Assignee)
                                        return filt.value === (assignee ? assignee : props.selectedItem.Assignee);
                                    })}
                                    isSearchable={true}
                                    onChange={(e) => {
                                        setAssignee(e.value);
                                    }}
                                />
                                {*/}
                                </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Dependencies</label>
                            </td>
                            <td></td>
                            <td>
                            <input 
                                    style={inputStyle} type="text" 
                                    defaultValue={(dependencies ? dependencies : props.selectedItem?.dependencyRawText)} 

                                    onChange={(e) =>{
                                        if (e.target.value === props.selectedItem?.dependencyRawText) {
                                            setDependencies(null);
                                        }
                                        else {
                                            setDependencies(e.target.value);
                                        }
                                    }}                            
                                ></input>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Due Date</label>
                            </td>
                            <td></td>
                            <td>
                                <input
                                    style={inputStyle}
                                    type="date"
                                    defaultValue={dueDate ? dueDate : tempDueDate}
                                    
                                    onChange={(e) => {
                                        setDueDate(e.target.value);
                                        if (e.target.value === props.selectedItem?.dueDate) {
                                            // handle change logic here
                                        }
                                    }}
                                />
                            </td>
                        </tr>

                        <tr>                        
                            <td>
                                <label>Progress</label> 
                            </td>
                            <td></td>
                            <td>                    
                                <input 
                                    style={{width:width_proportion2, textAlign:"right"}} type="text" pattern="[0-9]*"
                                    defaultValue={(progress ? progress : props.selectedItem?.progress)} 

                                    onChange={(e) =>{
                                        if (e.target.value === props.selectedItem?.progress) {
                                            setProgress(null);
                                        }
                                        else {
                                            setProgress(e.target.value);
                                        }
                                    }}                            
                                ></input>&nbsp;&nbsp;%

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Status</label> 
                            </td>
                            <td></td>
                            <td>                    
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    options={props.statusList}
                                    style={{ width: width_proportion1  }}
                                    name="status"
                                    value={props.statusList.filter((filt) => {
                                        return filt.value === (ticketStatus ? ticketStatus : props.selectedItem.status);
                                    })}
                                    isSearchable={true}
                                    onChange={(e) => {
                                        setTicketStatus(e.value);
                                    }}
                                />
                            </td>

                        </tr>
                        <tr>
                            <td>
                                Quarters
                            </td>
                            <td></td>
                            <td>
                                {props.selectedItem.period.join(", ")}
                        {/*}
                               <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    options={props.periodList}
                                    style={{ width: "40px"  }}
                                    isMulti
                                    defaultValue={props.periodList.filter((item) => {return props.selectedItem.period.includes(item.value)})
                                    //    [props.periodList[2], props.periodList[3]]
                                    } 
                                    name="status"
                                    isSearchable={true}
                                    onChange={(e) => {
                                        setTicketStatus(e.value);
                                    }}
                                />
                            {*/}
                            </td>

                        </tr>

                    </tbody>
                </table>


            </Modal.Body>
            <Modal.Footer>
            {isChanged() && 
                <Button onClick={()=>{
                    //TODO Implement save

                    const newItem = [];
                    //var _isChanged = false;

                    UpdateNewIfChanged(title, props.selectedItem.title, "/fields/"+tconst.AF_TITLE, newItem);
                    UpdateNewIfChanged(ticketStatus, props.selectedItem.status, "/fields/"+tconst.AF_STATE, newItem);
                    // TODO Fix updateing Assignee
                    UpdateNewIfChanged(assignee, props.selectedItem.assignee, "/fields/"+tconst.AF_ASSIGNED_TO, newItem);
                    UpdateNewIfChanged(dependencies, props.selectedItem.dependencies, "/fields/"+tconst.AF_DEPENDECIES, newItem);
                    UpdateNewIfChanged(dueDate, props.selectedItem.dueDate, "/fields/"+tconst.AF_DUE_DATE, newItem);

                    UpdateNewIfChanged(progress, props.selectedItem.progress, "/fields/"+tconst.AF_PROGRESS, newItem);
                    //TODO: Fix updating periods
                    UpdateNewIfChanged(periods, props.selectedItem.period, "period", newItem);

                    console.log("2024-04-30 - NewItem:  "+JSON.stringify(newItem, null, 3));


                    if (Object.keys(newItem).length > 0) {
                        close({itemId : props.selectedItem.id, data: newItem});
                    }
                    else {
                        close(null);
                    }
                }} 
                variant="primary">
                Save
              </Button>}

            {<Button onClick={()=>{
                close();
                }} 
            variant={(isChanged() ? "secondary" : "primary")}>
                { (isChanged() ? "cancel" : "Close")}
              </Button>}
              
            </Modal.Footer>
            </Modal>
        </>
    )
    }
    else {
        return <></>
    }

    function close(returnData = null){
        setTitle(null)
        setTicketStatus(null)
        setAssignee(null);
        setProgress(null);
        setDependencies(null);
        setPeriods(null);
        props.handleClose(returnData)

    }
    function isChanged()
    {
        return (title !== null 
            || ticketStatus !== null 
            || assignee !== null 
            || progress !== null 
            || periods !== null 
            || dependencies !== null 
            || dueDate !== null
        )
    
    }
    
}

export default EditOKR;

function UpdateNewIfChanged(element, itemAttribute, fieldname , newItem) {
    if (element && element !== null) {
        const _element =         {
            "op": "replace",
            "path": fieldname,
            "value": element
          };

        if (_element !== itemAttribute) {
            newItem.push(_element);
            return true;
        }
    }
    return false;
}
