export default function ParseArea (values, parentPath = ""){

    const _areas = values?.map((item) => {
        let _areaList = []
        if (item.structureType === "area"){
            const _path = parentPath + "\\" + item.name
            _areaList.push(_path)
            if (item.hasChildren) {
                const _childrenAreas = ParseArea(item.children, _path);
                _areaList.push(..._childrenAreas)
            }
            else {
                return _path;
            }
        }
        return _areaList;
    })

    return _areas.flat(1);
}
