import React, {useState} from "react";
import getStatusColor from "./getStatusColor";
import { getProgressColor } from "./getProgressColor";
import { getDeadlineColor } from "./getDeadlineColor";
import * as tconst from "../common/Constants";
import { Button, Tooltip } from "antd";
import { PlusOutlined, MinusOutlined,  FileAddTwoTone } from "@ant-design/icons";
import store from "store2";


//Feature flag:
const showPeriods = false;

const OKRTable = ({objectives, onItemClick, onCreateNewKR, onCreateNewObj}) => {
    // eslint-disable-next-line no-unused-vars
    const [openAdo,setOpenAdo] = useState(false)    
    const [collapsed, setCollapsed] = useState(store.get(tconst.STORE_SELECTED_COLLAPSED) ? store.get(tconst.STORE_SELECTED_COLLAPSED) : {});
    let oIndex = 0;
    let krIndex = 0;

    return(
        <>
            <table className="table table-striped table-bordered smalltable" style={{padding: 0,  margin: 0 }}>
                <thead>
                    <tr>
                        <th width="5%" colSpan="1">#</th>
                        <th colSpan="1" style={{textAlign:"center"}}> 
                            <Tooltip title="Add Objective">                       
                                <Button  type="ghost" size="medium" icon={<FileAddTwoTone/>} onClick={() =>{onCreateNewObj()}}/>
                            </Tooltip>
                        </th>
                        <th colSpan="2">Name</th>
                        <th colSpan="2">Status</th>
                        <th width="15%">Dependencies</th>
                        <th>Progress</th>
                        {showPeriods && <th>Periods</th>}
                        <th>Deadline</th>
                        <th>Assignee</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        objectives.map((obj) => {                                    
                            krIndex= 0;
            
                            return(
                                <>  
                                    <tr  style={{fontWeight: 'bold'}} key={obj.id}>
                                        <th >
                                            {obj.children.length !== 0 && <Tooltip title={( collapsed[obj.id]) ? "Expand":"Collapse"}>
                                                <Button shape="circle" size="small"  onClick={() =>{
                                                    const newCollapsed = {...collapsed, [obj.id]: !collapsed[obj.id]};
                                                    store.set(tconst.STORE_SELECTED_COLLAPSED, newCollapsed);
                                                    setCollapsed(newCollapsed);

                                                }}>
                                                    {( collapsed[obj.id]) ?   <PlusOutlined />: <MinusOutlined />}
                                                </Button>
                                            </Tooltip>
                                            }
                                            &nbsp; {++oIndex} 
                                            </th>
                                        <th style={{textAlign:"center"}} >
                                            {
                                                // Hide button for unparented
                                                (obj.id !== 0 && (!collapsed[obj.id] || collapsed[obj.id] === false || obj.children.count === 0)) && 
                                                <Tooltip title="Add Key Result">
                                                    <Button type="ghost" size="medium" icon={<FileAddTwoTone/>} onClick={() =>{onCreateNewKR(obj)}}></Button>
                                                </Tooltip>
                                            }
                                        </th>
                                        <td colSpan="2" >
                                            {!openAdo && <Button type="link" onClick={()=> {onItemClick(obj)}} style={{textAlign:"left", fontWeight: 'bold', fontSize: '14px' }}>[{obj.id}] {obj.title}</Button>}
                                            {openAdo && <a href={tconst.AZURE_DEVOPS_BASE_TICKET_URI+obj.id} target="_blank" rel="noopener noreferrer">
                                                    [{obj.id}] {obj.title} </a>}
                                        </td>
                                        <td colSpan="2" style={getStatusColor(obj.status)}>
                                        <div style={getStatusColor(obj.status)} >
                                            {obj.status}
                                            </div>
                                            </td>
                                        <td>{
                                            obj.dependencies?.map((item) => {
                                                if (item.itemId) {
                                                    return <><a href={tconst.AZURE_DEVOPS_BASE_TICKET_URI+item.itemId}  target="_blank" rel="noopener noreferrer">{item.team}</a> <br/></>
                                                }else {
                                                    return <>{item.team} <br/></>
                                                }
                                            })
                                        }
                                        </td>
                                        <td className="text-right" style={getProgressColor(obj.progress)}>
                                            <div style={getProgressColor(obj.progress)}>
                                                {obj.progress} %
                                            </div>
                                        </td>
                                        {showPeriods && <td>{obj.period.join(", ")}</td>}
                                        <td>{obj.dueDateText}</td>
                                        <td>{obj.Assignee}</td>
                                    </tr>

                                    {!!!collapsed[obj.id] && obj.children.map((kr) => {
                                        return(
                                            <tr key={kr.id}>
                                                <td width="1%">&nbsp;</td>
                                                <th>{oIndex}.{++krIndex}</th>
                                                <td width="1%"></td>
                                                <td width="30%">

                                                {!openAdo && <Button type="link" onClick={()=> {onItemClick(kr)}} style={{textAlign:"left", fontSize: '16px' }}>[{kr.id}] {kr.title}</Button>}

                                                {openAdo && <a href={tconst.AZURE_DEVOPS_BASE_TICKET_URI+kr.id} target="_blank" rel="noopener noreferrer">
                                                    [{kr.id}] {kr.title} </a>
                                                }
                                                </td>
                                                <td width="1%"></td>
                                                <td  style={getStatusColor(kr.status)}>
                                                    {/*This Div is here to overcome a bug where the table and td background is lost on hardcopies*/}
                                                    <div style={getStatusColor(kr.status)} >
                                                        {kr.status}

                                                    </div>
                                                    </td>
                                                <td>{
                                                    kr.dependencies?.map((item) => {
                                                        if (item.itemId) {
                                                            return <><a href={tconst.AZURE_DEVOPS_BASE_TICKET_URI+item.itemId} target="_blank" rel="noopener noreferrer">{item.team}</a> <br/></>
                                                        }else {
                                                            return <>{item.team} <br/></>
                                                        }
                                                    })
                                                }
                                                </td>
                                                <td className="text-right" style={getProgressColor(kr.progress)}>
                                                    {/*This Div is here to overcome a bug where the table and td background is lost on hardcopies*/}
                                                    <div style={getProgressColor(kr.progress)}>
                                                        {kr.progress} %
                                                    </div>
                                                </td>
                                                {showPeriods && <td>{kr.period.join(", ")}</td>} 
                                                <td style={getDeadlineColor(kr.dueDate, kr.status)}>
                                                    <div style={getDeadlineColor(kr.dueDate, kr.status)}>
                                                        {kr.dueDateText}
                                                    </div>
                                                </td>
                                                <td>{kr.Assignee}</td>
                                            </tr>
                                        )
                                    })}
                                </>
                            )
                        })
                    }
                </tbody>
            </table>

        </>
    )
}



export default OKRTable;