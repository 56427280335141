import * as tConst from "../common/Constants";

export function getProgressColor(progress) {
    if (progress === 0) {
        return {backgroundColor: "#F3F8FF", color:"blue", fontWeight: 'bold'};
    }
    else if (progress < 25) {
        return {backgroundColor: "darksalmon", color:"white", fontWeight: 'bold' }
    }
    else if (progress < 70) {
        return {backgroundColor: "#F0DBAF", fontWeight: 'bold'};
    }
    else if (progress < 90) {
        return {backgroundColor: "#A5DD9B", color:"darkgreen", fontWeight: 'bold'}
    }
    else if (progress < 100) {
        return {backgroundColor: "#9DBC98", color:"white", fontWeight: 'bold'};
    }
    else if (Math.round(progress,) > 99) {
        //return {backgroundColor: "#A5DD9B", color:"darkgreen", fontWeight: 'bold'}
        return { backgroundColor: tConst.DONE_BACKGROUND_COLOR, color: "white", fontWeight: 'bold' };
    }
    else {
        return { backgroundColor: tConst.INVALID_BACKGROUND_COLOR, fontWeight: 'bold' };
    }

}
