import React, { useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import * as tconst from "../common/Constants";
//import * as tConst from "../common/Constants";



const AddOKR = ({...props}) => {
    // eslint-disable-next-line no-unused-vars
    const [title, setTitle] = useState("");

    const inputStyle = { width:'100%' }
    
    if (props.showAddOKR){
     
    return(
        <>
            <Modal size="sm" show={props.showAddOKR} >
                <Modal.Header>
                    <Modal.Title>
                        Add {props.input.type}
                    </Modal.Title>
                </Modal.Header>
            <Modal.Body>
                <table>
                    <tbody>
                        <tr>
                            <td width="10%">
                                <label>Title</label> 
                            </td>
                            <td width="1%"></td>
                            <td width="35%">                    
                                <input 
                                    style={inputStyle} 
                                    onChange={(e) =>{
                                        setTitle(e.target.value);
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>


            </Modal.Body>
            <Modal.Footer>
            { 
                <Button variant="primary" onClick={()=>{
                    const newItem1 = {
                        newTitle: title,
                        parentId: props.input.parentId,
                        type: encodeURIComponent(props.input.type),
                        tags: props.input.tags ? props.input.tags : "",
                        path: props.input.path
                    };
                    const data = CreateData(newItem1)
                    const  newItem = { ...newItem1, data: data};
                    close(newItem);
                }} disabled={title === ""}>
                Save
              </Button>}

            {<Button variant="secondary" onClick={()=>{
                    close();
                }} >
                Cancel
              </Button>}
              
            </Modal.Footer>
            </Modal>
        </>
    )
    }
    else {
        return <></>
    }

    function close(returnData = null){
        setTitle(null)
        props.handleClose(returnData)

    }
    
}

export default AddOKR;

function CreateData(newItem) {
    const data = [
        {
            "op": "add",
            "path": "/fields/System.Title",
            "from": null,
            "value": newItem.newTitle
        },
        {
            "op": "add",
            "path": "/fields/System.AreaPath",
            "value": newItem.path
        },    
       {
            "op": "add",
            "path": "/fields/System.Tags",
            "value": newItem.tags
        }
    ]

    if (newItem.parentId !== null && newItem.parentId!== 0) {
        data.push({
            "op": "add",
            "path": "/relations/-",
            "value": {
            "rel": "System.LinkTypes.Hierarchy-Reverse",
            "url": `https://dev.azure.com/sensio/OKR/_apis/wit/workItems/${newItem.parentId}`
            }
        });
    
    }

    return data;
}
