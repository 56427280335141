import { handleResponse, handleError } from "./apiUtils";
import * as tConst from "../common/Constants";


function getHeaders(auth, ContentType = "application/json") {
  const auth_token = btoa(auth.un + ":" + auth.pw);

  const headers = {
      "Accept": "application/json",
      'Content-Type': ContentType,
      "Authorization": `Basic ${ auth_token }`,
  };
  return headers;
}

export function getAreaPath(auth) {

  const headers = getHeaders(auth);

  //https://dev.azure.com/sensio/okr/_apis/wit/classificationnodes?&$depth=5&errorPolicy={errorPolicy}&api-version=5.1
  const url = tConst.AZURE_DEVOPS_BASE_URI+"/okr/_apis/wit/classificationnodes?&$depth=5&errorPolicy={errorPolicy}&api-version=5.1";

  return fetch(url, { method: "GET", headers: headers }).then(handleResponse).catch(handleError);
}


export function getProjects(auth) {

  const headers = getHeaders(auth);
  const url = tConst.AZURE_DEVOPS_API_URI+"projects";

  return fetch(url, { method: "GET", headers: headers }).then(handleResponse).catch(handleError);
}


export function getItemsList(auth, query, project = null) {

  
  const headers = getHeaders(auth);

  let url = ""
  if (project == null || project === "") {
    url = tConst.AZURE_DEVOPS_API_URI+"wit/wiql?api-version=7.2-preview.2";
  }
  else {
    url = tConst.AZURE_DEVOPS_BASE_URI+project+"/_apis/wit/wiql?api-version=7.2-preview.2"
  }

  return fetch(url, { method: "POST", headers: headers, body: JSON.stringify(query)}).then(handleResponse).catch(handleError);
}

export function getItems(auth, idList) {

  if (idList.length > 0 ){

    //     const queryString = JSON.stringify({ids: [297,299,300],   fields: [
    const queryString = JSON.stringify({ids: idList,  fields: [
      tConst.AF_ID,
      tConst.AF_TITLE,
      tConst.AF_WORKITEM_TYPE,
      tConst.AF_STATE,
      tConst.AF_REMAINING_WORK,
      tConst.AF_PLANNING_STATUS,
      tConst.AF_PLANNING_PRIORITY,
      tConst.AF_START_ITERATION,
      tConst.AF_END_ITERATION,
      tConst.AF_INITIATIV, 
      tConst.AF_ASSIGNED_TO,
      tConst.AF_PARENT,
      tConst.AF_AREA_PATH,
      tConst.AF_TAGS,
      tConst.AF_PROGRESS,
      tConst.AF_DEPENDECIES,
      tConst.AF_DUE_DATE,
      tConst.AF_PROJECT
    ]});

    
    console.log("query: "+JSON.stringify(queryString, null, 4));

    const headers = getHeaders(auth);
    
    const url = tConst.AZURE_DEVOPS_API_URI+"wit/workitemsbatch?$top=500&api-version=7.2-preview.1&$expand=relations";
    
    return fetch(url, { method: "POST", headers: headers, body: queryString}).then(handleResponse).catch(handleError);
  }
}

export function getItem(auth, id) {

  const isNotNum = isNaN(id);
  if (!isNotNum ){

    const headers = getHeaders(auth);
    
    const url = "https://dev.azure.com/sensio//_apis/wit/workitems/"+id+"?api-version=7.1-preview.3&$expand=relations"
    
    return fetch(url, { method: "GET", headers: headers}).then(handleResponse).catch(handleError);
  }
}

export function setIteration(auth,ticketId, startDate, endDate){
  const data = JSON.stringify([
    {
      "op": "replace",
      "path": "/fields/Custom.StartIteration",
      "value": startDate
    },
    {
      "op": "replace",
      "path": "/fields/Custom.EndIteration",
      "value": endDate
    },
  ])  
  const headers = getHeaders(auth, "application/json-patch+json");
    
  const url = tConst.AZURE_DEVOPS_API_URI+"wit/workitems/"+ticketId+"?api-version=7.1-preview.3"
      
      return fetch(url, { method: "PATCH", headers: headers, body: data}).then(handleResponse).catch(handleError);
  
}

export function moveTicketIteration(auth,selectedTicket, numberOfDays){

  const _start = new Date(selectedTicket.fields[tConst.AF_START_ITERATION]);
  _start.setDate(_start.getDate() + numberOfDays)
  const _end = new Date(selectedTicket?.fields[tConst.AF_END_ITERATION]);
  _end.setDate(_end.getDate() + numberOfDays)
  
  const data = JSON.stringify([
    {
      "op": "replace",
      "path": "/fields/Custom.StartIteration",
      "value": _start
    },
    {
      "op": "replace",
      "path": "/fields/Custom.EndIteration",
      "value": _end
    },
  ])  
  const headers = getHeaders(auth, "application/json-patch+json");
    
  const url = tConst.AZURE_DEVOPS_API_URI+"wit/workitems/"+selectedTicket.id+"?api-version=7.1-preview.3"
      
      return fetch(url, { method: "PATCH", headers: headers, body: data}).then(handleResponse).catch(handleError);
  
}

export function updateTicket(auth, ticketId, data){
  const headers = getHeaders(auth, "application/json-patch+json");
  
  const url = tConst.AZURE_DEVOPS_API_URI+"wit/workitems/"+ticketId+"?api-version=7.1-preview.3"    
  return fetch(url, { method: "PATCH", headers: headers, body: data}).then(handleResponse).catch(handleError);
    
}

export function createTicket(auth, project, ticketType, data){
  const headers = getHeaders(auth, "application/json-patch+json");
    
  const url = tConst.AZURE_DEVOPS_BASE_URI+project+"/_apis/wit/workitems/$"+ticketType+"?api-version=7.1-preview.3"

  return fetch(url, { method: "PATCH", headers: headers, body: data}).then(handleResponse).catch(handleError);
}

export async function  getChildrenList(auth, id)
{
  let _children = [];
  
  const response = await getItem(auth, id);
  if (response.relations) {

    response.relations.map((relation) => {
      if (relation.attributes.name ==="Child"){
        const child = relation.url.split("/");
        _children.push(child[child.length-1])
      }
      return null;
    })
  }
  
   return _children;
  
}


export function extendTicketIterations(auth, selectedTicket, numberOfDays){
  const _end = new Date(selectedTicket?.fields[tConst.AF_END_ITERATION]);
  //Todo - implement check when reducing this to the same day or less
  _end.setDate(_end.getDate() + numberOfDays)
  
  const data = JSON.stringify([
    {
      "op": "replace",
      "path": "/fields/Custom.EndIteration",
      "value": _end
    },
  ])
  console.log("extendTicketIterations.content: "+ data);
  
  const headers = getHeaders(auth, "application/json-patch+json");
    
  const url = tConst.AZURE_DEVOPS_API_URI+"wit/workitems/"+selectedTicket.id+"?api-version=7.1-preview.3"
      
      return fetch(url, { method: "PATCH", headers: headers, body: data}).then(handleResponse).catch(handleError);
}

