import React from "react";
import Select from "react-select";

const Toolbar = ({...props}) => {

    return (
        <>
            <table width="100%">
                <tbody>
                    <tr>
                        <td width="25%">
                            {" "}
                            <label>Path</label>
                        </td>
                        <td width="10%">Period</td>
                        <td width="10%">Status</td>
                        <td width="10%">Assignee</td>
                        <td width="8%">Due within</td>
                        <td width="50%"></td>
                    </tr>
                    <tr>
                        <td >
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                options ={props.areas}
                                style={{ width: "40px"  }}
                                name=""
                                value={props.areas.filter((filt) => {
                                    return filt.value === props.selectedArea
                                })}
                                isSearchable={true}
                                onChange={props.onSelectedAreaChange}
                            />
                        </td>
                        <td >
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                options ={props.periodList}
                                style={{ width: "40px"  }}
                                name=""
                                value={props.periodList.filter((filt) => {
                                    return filt.value === props.selectedPeriod
                                })}
                                isSearchable={true}
                                onChange={props.onSelectedPeriodChange}
                            />
                        </td>
                        <td >
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                options={props.statusList}
                                style={{ width: "40px"  }}
                                name=""
                                value={props.statusList.filter((filt) => {
                                    return filt.value === props.selectedStatus
                                })}
                                isSearchable={true}
                                onChange={props.onSelectedStatusChange}
                            />
                        </td>
                        <td >
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                options={props.assigneeList}
                                style={{ width: "40px"  }}
                                name=""
                                value={props.assigneeList ? props.assigneeList.filter((filt) => {
                                    return filt.value === props.selectedAssignee
                                }) : ""}
                                isSearchable={true}
                                onChange={props.onSelectedAssigneeChange}
                            />
                        </td>
                        <td>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                options={props.dueDateFilterList}
                                style={{ width: "40px"  }}
                                name="DueDateFilter"
                                value={props.dueDateFilterList ? props.dueDateFilterList.filter((filt) => {
                                    return filt.value === props.selectedDueDateFilter
                                }) : ""}
                                isSearchable={true}
                                onChange={props.onSetSelectedDueDateFilter}
                            />

                        </td>
                        <td>
                            <button onClick={props.onRefreshClick} >Refresh</button>
                        </td>

                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default Toolbar;
